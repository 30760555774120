import fullpage from 'fullpage.js';
new fullpage('#fullpage', {
  menu: '.nav',
  anchors: ['home', 'about', 'works', 'contact'],
  licenseKey: '910C9836-A0BD4559-AA0CC540-F3D5D907',
});

const allWorks = document.querySelectorAll('.work');
// const demoReel = document.querySelector('.demoreel');
// const openStill = document.querySelector('#openStill');
document.querySelector('.footerAddress').addEventListener('click', (e) => {
  const [a, b, c, d] = ['mailto:', 'studio', '@slow', 'solutions.com'];
  window.open(a + b + c + d);
});

const closeBut = document.querySelectorAll('button.closeButton');
let viewOpened = false;

closeBut.forEach((cb) => {
  cb.addEventListener('click', (e) => {
    if (viewOpened) {
      allWorks.forEach((work) => {
        if (work.querySelector('iframe')) work.querySelector('iframe').setAttribute('src', '');
        work.classList.remove('hidden');
        work.classList.remove('act');
      });
      closeBut.forEach((cb2) => {
        cb2.classList.remove('visible');
      });
      setTimeout(() => {
        viewOpened = false;
      }, 300);
    }
  });
});

// openStill.addEventListener('click', (e) => {
//   demoReel.classList.add('demoAct');
// });

allWorks.forEach((w) => {
  w.addEventListener('click', (e) => {
    const workEl = e.composedPath().find((el) => {
      if (el.classList) {
        return Array.from(el.classList).some((cl) => cl == 'clickable');
      }
    });

    if (!workEl || viewOpened) return;

    const iframe = workEl.querySelector('iframe');
    if (iframe) iframe.setAttribute('src', iframe.dataset.s);

    workEl.classList.add('act');
    closeBut.forEach((cb) => {
      cb.classList.add('visible');
    });
    allWorks.forEach((work) => {
      if (work != workEl) {
        work.classList.add('hidden');
      }
    });

    setTimeout(() => {
      viewOpened = true;
    }, 300);
  });
});

let vh;
let ratio = window.innerWidth / window.innerHeight;

windowH();

function windowH() {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', () => {
  ratio = window.innerWidth / window.innerHeight;
  windowH();
});
